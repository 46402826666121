<template>
  <section>
    <!-- form-geral -->
    <form @submit.prevent="editMarca">
      <div class="card card_border">
        <div class="cards__heading">
          <h3>Dados Gerais</h3>
        </div>
        <div class="card-body">
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">descricao</label>
              <input
                type="text"
                class="form-control input-style"
                id="inputEmail4"
                placeholder="descricao"
                name="descricao" v-model="marca.descricao"
              />
              
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label for="inputEmail4" class="input__label">imagem</label>
              <image-marca-upload
                v-model="marca.imagem"
              />
              
            </div>
          </div>
        </div>
        <div class="card-footer">

          <button type="submit" class="btn btn-primary btn-style">
            Salvar
          </button>
          
          <button class="btn btn-link"
            @click="()=>$router.push({ name: `${currentMeta.base}.search` })">
            Voltar
          </button>

        </div>
      </div>
    </form>
    <!-- /form-geral -->
  </section>
</template>

<script>

import { onMounted } from "vue";
import { useRouter } from "vue-router";

import useCommon from "@/common";
import useMarca from '@/composables/Marca';

export default {
  props: {
    id: {
      required: false,
          type: String
    }
  },

  setup(props) {

    const { openToast } = useCommon();
    const { status, message, error, marca, getMarca, updateMarca } = useMarca();
    const router = useRouter();
    const currentMeta = router.currentRoute.value.meta;

    const showMessages = () => {
      if(status.value){
        openToast(message.value, 'success');
      }else{
        openToast(error.value, 'error');
      }
    };

    //edit
    const editMarca = async () => {
      await updateMarca(props.id);
      showMessages();
    }

    onMounted(function(){
      getMarca(props.id);
    });

    return {
      marca,
      editMarca,
      currentMeta
    };
  },
};
</script>

import axios from "axios";
import { ref } from "vue"
// import router from "../router";

export default function useMarca() {
    
    const meta = ref([]);
    const status = ref(Boolean);
    const error = ref('');
    const message = ref('');
    const paramsSearch = ref({});

    const marcas = ref([]);
    const marca = ref([]);

    const getMarcas = async (page=1) => {
        const qs = new URLSearchParams(paramsSearch.value);
        let response = await axios.get('/marcas?page='+page+'&'+qs);
        marcas.value = response.data.data;
        meta.value = response.data.meta;
    };

    const getMarca = async (id) => {
        let response = await axios.get('/marcas/' + id);
        marca.value = response.data.data;
    };

    const destroyMarca = async (id) => {
        error.value = "";
        message.value = "";
        status.value = true;

        try {
            await axios.delete('/marcas/' + id).then((response) => {
                message.value = response.data.message;
            });
        } catch (e) {
            status.value = false;
            if(e.response.status === 422){
                error.value += e.response.data.errors + "<br />";
            }
        }
    };

    const storeMarca = async (data) => {
        
        error.value = "";
        message.value = "";
        status.value = true;

        try {
            await axios.post('/marcas', data).then((response) => {
                message.value = response.data.message;
            });
        } catch (e) {
            status.value = false;
            if(e.response.status === 422){
                for(const key in e.response.data.errors){
                    error.value += e.response.data.errors[key][0] + "<br />";
                }
            }
        }
    };

    const updateMarca = async (id) => {

        error.value = "";
        message.value = "";
        status.value = true;

        try {
            await axios.patch('/marcas/' + id, marca.value).then((response) => {
                message.value = response.data.message;
            });
        } catch (e) {
            status.value = false;
            if(e.response.status === 422){
                for(const key in e.response.data.errors){
                    error.value += e.response.data.errors[key][0] + "<br />";
                }
            }
        }
    };

    return {

        meta,
        error,
        status,
        message,
        paramsSearch,

        marcas,
        marca,
        getMarca,
        getMarcas,
        storeMarca,
        updateMarca,
        destroyMarca
    }
}